import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import qbTransparent from '../assets/qbTransparent.png'
import LogoutIcon from '@mui/icons-material/Logout'
import {useNavigate} from 'react-router-dom'
import {Grid} from '@mui/material'

export default function Header() {
    const navigate = useNavigate()
    let user = {}
    let userStr = localStorage.getItem('user')
    try {
        user = JSON.parse(userStr)
    } catch (_) {

    }
    return (
        <AppBar position="static" sx={{background: 'white'}} elevation={0}>
            <Toolbar>
                <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}} container spacing={1}>
                    <Grid item xs={12} md={9} style={{}}>
                        <Typography variant="h6" noWrap component="div" sx={{display: {xs: 'none', sm: 'block'}}}>
                            <img
                                src={qbTransparent}
                                alt=""
                                style={{height: '13vh', cursor: 'pointer'}}
                                onClick={() => {
                                    navigate('/')
                                }}
                            />
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                gap: '20px',
                                paddingRight: {md: '20px', sm: '10px'},
                            }}
                        >
                            <span
                                style={{
                                    color: 'black',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                                onClick={() => {
                                    navigate('/profile')
                                }}
                            >
                                {[user?.firstName, user?.lastName].filter(item => item?.trim()).join(' ') || 'Profile'}
                            </span>
                              <span
                                  style={{
                                      color: 'black',
                                      fontWeight: 'bold',
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                  }}
                                  onClick={() => {
                                      localStorage.removeItem('token')
                                      navigate('/login')
                                  }}
                              >
                                  <LogoutIcon

                                  />
                                Logout
                              </span>
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}
