import { Box, Container, Grid } from '@mui/material'
import { TextFieldInput } from './TextField'

export const UCCAddendum = ({}) => {
  return (
    <>
      <h1 style={{ marginLeft: '3%' }}>UCC and Merchant Addendum</h1>
      <Container maxWidth="xl">
        <Grid item xs={12} container spacing={3}>
          <TextFieldInput
            name="guarantor"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Guarantor"
            disabled={true}
          />

          <TextFieldInput
            name="merchant"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Merchant"
            disabled={true}
          />

          <TextFieldInput
            name="legalEntity"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Legal Entity(s)"
            disabled={true}
          />

          <TextFieldInput
            name="date"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Date"
            disabled={true}
          />
        </Grid>
      </Container>
    </>
  )
}
