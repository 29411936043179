import { Grid, InputAdornment, TextField } from '@mui/material'
import { ErrorMessage, Field } from 'formik'

export const TextPercentageField = ({
  xs = 12,
  md = 4,
  style = {},
  name,
  error,
  touched,
  spanStyle = {
    float: 'left',
    fontWeight: 'bold',
  },
  value = null,
  heading,
  disabled = false,
  onChange
}) => {
  return (
    <Grid item xs={xs} md={md} style={style}>
      <span style={spanStyle}>{heading}</span>
      <Field
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        name={name}
        as={TextField}
        onChange={onChange}
        disabled={disabled}
        variant="outlined"
        type="number"
        fullWidth
        margin="normal"
        error={error && touched}
        helperText={<ErrorMessage name={name} />}
        value = {value}
      />
    </Grid>
  )
}
