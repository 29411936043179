import { Grid, NativeSelect } from '@mui/material'
import { ErrorMessage } from 'formik'
import { BootstrapInput } from './BootStrapInput'

export const SelectFieldInput = ({
  xs = 12,
  md = 4,
  style = {},
  name,
  spanStyle = { fontWeight: 'bold' },
  heading,
  onChange,
  selected,
  options = [],
}) => {
  return (
    <Grid item xs={xs} md={md} style={style}>
      <span style={spanStyle}>{heading}</span>
      <NativeSelect
        id="demo-customized-select-native"
        name={name}
        value={selected}
        onChange={onChange}
        input={<BootstrapInput />}
        fullWidthWidth
        className="select_items"
      >
        {options?.map((item) => {
          return <option value={item.value}>{item.label}</option>
        })}
      </NativeSelect>
      <p className="errorMsg">
        <ErrorMessage name={name} />
      </p>
    </Grid>
  )
}
