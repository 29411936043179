import * as React from 'react'
import { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import PaginationComponent from '../components/UI-Pagination/PaginationComponent'
import { ReactMultiEmail } from 'react-multi-email'
import {
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  Modal,
  Box,
  CircularProgress,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { ToastContainer, toast } from 'react-toastify'
import axios from '../utils/axios'
import defaultAxios from 'axios'
import { pdfjs } from 'react-pdf'
import moment from 'moment'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import Dialog from '@mui/material/Dialog'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import 'react-multi-email/dist/style.css'
import { useNavigate } from 'react-router-dom'
import { periodicFrequencyValue } from '../utils/helperFunctions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
export default function ContractList() {
  const navigate = useNavigate()
  const [orderBy, setOrderBy] = useState('createdAt')
  const [order, setOrder] = useState('asc')
  const [focused, setFocused] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [contractData, setContractData] = React.useState([])
  const [tempContractData, setTempContractData] = React.useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState()
  const [refresh, setRefresh] = React.useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [emails, setEmails] = React.useState([])
  const [emailContractData, setEmailContractData] = React.useState({})
  const [estimatedTerms, setEstimatedTerms] = useState([])
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const handleDateChange = (event) => {
    const dateValue = event.target.value
    setSelectedDate(dateValue)

    if (dateValue) {
      const apiUrl = `filterByDate?date=${dateValue}`

      axios
        .get(apiUrl)
        .then((data) => {
          setContractData(data?.data?.data?.contracts)
          setTempContractData(data?.data?.data?.contracts)
          setTotalSize(1)
          setCurrentPage(1)
        })
        .catch((error) => {
          toast.error(error.response?.data?.data?.message)
        })
    } else {
      fetchAllData()
    }
  }
  const handleSort = (property) => {
    const sortedData = contractData
    console.log('original:', sortedData)

    const isAsc = orderBy === property && order === 'asc'
    const sortOrder = isAsc ? 'desc' : 'asc'

    sortedData.sort((a, b) => {
      const dateA = new Date(a[property])
      const dateB = new Date(b[property])

      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA
    })
    console.log('sorted Data:', sortedData)

    setOrder(sortOrder)
    setOrderBy(property)

    setContractData(sortedData)
    console.log('Sorted Data:', sortedData)
  }

  const [totalSize, setTotalSize] = React.useState(5)

  const handleDialogOpenQtm = (id, termsValue) => {
    navigate(`/qtm-contract/${id}`, { state: { termsValue } });
  };
  
  const handleDialogOpenContract = (id, termsValue) => {
    navigate(`/dynamic-contract/${id}`, { state: { termsValue } });
  };
  

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  React.useEffect(() => {
    fetchAllData()
  }, [refresh, currentPage, searchValue])

  const fetchAllData = () => {
    axios
      .get(`admin/contracts?size=${20}&page=${currentPage}&search=${searchValue}`)
      .then((data) => {
        setContractData(data?.data?.data?.contracts)
        setTempContractData(data?.data?.data?.contracts)
        const totalSizeData = Math.ceil(data?.data?.data?.totalContracts / 20)
        setTotalSize(totalSizeData)
      })
      .catch((error) => {
        toast.error(error.response?.data?.data?.message)
      })
  }

  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  })
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    outline: 'none',
  }
  const [open2, setOpen2] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)

  const handleOpen2 = () => setOpen2(true)
  const handleClose2 = () => setOpen2(false)

  const handleDeleteContract = async (id) => {
    axios
      .delete(`deleteContract/${id}`)
      .then((data) => {
        setRefresh(!refresh)
      })
      .catch((error) => {
        toast.error(error.response.data.data.message)
      })
  }
  const handleOwnerEmailSend = async () => {
    setLoader(true)
    let recipientsData = []

    if (emailContractData.type == 'Contract' || emailContractData.type == 'Loan_Agreement') {
      if (emailContractData.owner2Email !== '') {
        recipientsData = [
          {
            email: emailContractData.owner1Email,
            role: 'owner1',
          },
          {
            email: emailContractData.owner2Email,
            role: 'owner2',
          },
        ]

        emails.map((d, i) => {
          recipientsData.push({
            email: d,
            role: `user${i}`,
          })
        })
      } else {
        recipientsData = [
          {
            email: emailContractData.owner1Email,
            role: 'owner1',
          },
        ]

        emails.map((d, i) => {
          recipientsData.push({
            email: d,
            role: `user${i}`,
          })
        })
      }
    } else {
      emails.map((d, i) => {
        recipientsData.push({
          email: d,
          role: `user${i}`,
        })
      })
    }

    let data = {
      name: `${emailContractData.contractName} (${emailContractData.companyName})`,
      url: emailContractData.link,
      recipients: recipientsData,
      parse_form_fields: false,
    }

    defaultAxios
      .post(`https://api.pandadoc.com/public/v1/documents`, data, {
        headers: {
          Authorization: `API-Key 9731e671eae19ec4816d9cf423824e451a85e073`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((data) => {
        setTimeout(() => {
          defaultAxios
            .post(
              `https://api.pandadoc.com/public/v1/documents/${data?.data.id}/send`,
              {
                message: 'Hello, here is a new Funding Agreement for your review',
                // "silent": true
              },
              {
                headers: {
                  Authorization: `API-Key 9731e671eae19ec4816d9cf423824e451a85e073`,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then((data) => {
              toast.success('Email sent')
              handleClose()
              setLoader(false)
              setOpen2(false)
              setEmails([])
              console.log('emails.....', emails)
              console.log('daattttaaa', data.data.id)
              if (emails.length !== 0) {
                axios
                  .post(`addRecipients`, {
                    recipients: JSON.stringify(emails),
                    contractId: emailContractData.id,
                    pandaDocId: data?.data?.id,
                  })
                  .then(() => {})
                  .catch((error) => {
                    console.log(error)
                  })
              }
            })
            .catch((error) => {
              toast.error('An error occurred while sending email')
              console.log('error', error)
              setLoader(false)
              handleClose()
              setEmails([])
            })
        }, 10000)
      })
      .catch((error) => {
        toast.error('An error occurred')
        setLoader(false)
        handleClose()
      })
  }
  React.useEffect(() => {
    axios
      .get('recipientsByContract/' + emailContractData.id)
      .then((data) => {
        let rec = data.data.data.recipients

        if (rec.length > 0) {
          let arr = []

          rec.map((d) => {
            arr.push(d.email)
          })

          setEmails(arr)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [open2, dialogOpen])

  useEffect(() => {}, [emailContractData])
  const handleInputChange = (e) => {
    e.preventDefault()
    const query = e.target.value.toLowerCase()
    setSearchValue(query)
  }
// Function to format Funding Amount as currency
const formatFundingAmount = (amount) => {
  return `$${parseInt(amount).toLocaleString()}`;
}
  // const getTermsValue = (item) => {
  //   const initialAmount = item.initialPeriodicAmount
  //   const periodicFr = periodicFrequencyValue[item?.periodicFr] || 0
  //   const purchaseAmount = item.purchaseAmountFuture

  //   // Ensure all values are numbers and valid
  //   if (!isNaN(initialAmount) && !isNaN(periodicFr) && !isNaN(purchaseAmount) && initialAmount > 0 && periodicFr > 0) {
  //     return (purchaseAmount / (initialAmount * periodicFr)).toFixed(2)
  //   }
  //   return 0
  // }
  const getTermsValue = (item) => {
    const initialAmount = item.initialPeriodicAmount;
    const periodicFr = periodicFrequencyValue[item?.periodicFr] || 0;
    const purchaseAmount = item.purchaseAmountFuture;
  
    // Ensure all values are numbers and valid
    if (!isNaN(initialAmount) && !isNaN(periodicFr) && !isNaN(purchaseAmount) && initialAmount > 0 && periodicFr > 0) {
      const term = Math.floor(purchaseAmount / (initialAmount / periodicFr));
      return `${term}`;
    }
    return 0;
  }
  return (
    <>
      <div>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ outline: 'none' }}
        >
          <Box sx={modalStyle}>
            {emailContractData.type == 'Contract' ||
              (emailContractData.type == 'Loan_Agreement' && (
                <>
                  Email will be sent to following addresses:
                  <div style={{ marginTop: '5%' }}>
                    <b>Owner 1</b>: {emailContractData.owner1Email}
                  </div>
                  {emailContractData.owner2Email !== '' && (
                    <div style={{ marginTop: '5%' }}>
                      <b>Owner 2</b>: {emailContractData.owner2Email}
                    </div>
                  )}
                </>
              ))}
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: '5%' }}>
              {emailContractData.type == 'Contract' || emailContractData.type == 'Loan_Agreement'
                ? 'Send Contract to owners'
                : 'Send Email'}
            </Typography>

            <div style={{ marginTop: '5%' }}>
              <form>
                <h3>Recipients</h3>
                <ReactMultiEmail
                  placeholder="Enter email and press enter"
                  emails={emails}
                  onChange={(_emails) => {
                    setEmails(_emails)
                  }}
                  autoFocus={true}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        <div data-tag-item>{email}</div>
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                      </div>
                    )
                  }}
                />
                <br />
              </form>
              {loader ? (
                <>
                  <CircularProgress sx={{ marginTop: '5%' }} />
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    sx={{ marginTop: '5%', background: '#6040B0' }}
                    onClick={() => {
                      handleOwnerEmailSend()
                    }}
                  >
                    Send
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: '5%',
                      background: '#f44336',
                      marginLeft: '5%',
                    }}
                    onClick={() => {
                      handleClose2()
                      handleDialogClose()
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </div>
          </Box>
        </Modal>
      </div>
      <ToastContainer />
      <div>
        <Dialog fullScreen open={dialogOpen} onClose={handleDialogClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: 'sticky', top: 0, zIndex: 1300 }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit
              </Typography>
            </Toolbar>
          </AppBar>
        </Dialog>
      </div>

      {/* Send Contract to owners Modal  */}
      <Grid item xs={12} container className="table-header">
        <Grid item xs={12} md={3}>
          <Typography
            sx={{
              fontSize: { sm: '20px', md: '22px' },
              marginY: { md: '1em', sm: '1rem' },
            }}
          >
            Contracts
          </Typography>
        </Grid>

        <Grid item xs={12} md={9} display={'flex'} flexDirection={'row-reverse'} padding={2}>
          <FormControl item className="search-container">
            <TextField
              size="small"
              placeholder="Search"
              variant="outlined"
              className="search-container"
              style={{ marginRight: '6%' }}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <input
            type="date"
            placeholder="date"
            value={selectedDate}
            onChange={handleDateChange}
            className="input-field"
            style={{ marginRight: '2%' }} // Adding space to the right of the date input
          />
        </Grid>
      </Grid>

      <div
        style={{
          background: 'white',
          height: '80vh',
          width: '100%',
          overflow: 'auto',
          display: 'table',
          tableLayout: 'fixed',
        }}
      >
        <TableContainer xs={{ maxHeight: 400 }}>
          <Table sx={{ minWidth: { md: '650', sm: '100' } }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { md: 'large', sm: 'small' },
                    whiteSpace: 'nowrap',
                  }}
                >
                  Contract No.
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { md: 'large', sm: 'small' },
                    whiteSpace: 'nowrap',
                    maxWidth: 200,
                  }}
                >
                  Company Name
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { md: 'large', sm: 'small' },
                    whiteSpace: 'nowrap',
                  }}
                >
                  Funding Amount
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { md: 'large', sm: 'small' },
                    whiteSpace: 'nowrap',
                  }}
                >
                  Expected Terms
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { md: 'large', sm: 'small' },
                    whiteSpace: 'nowrap',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === 'createdAt'}
                    direction={orderBy === 'createdAt' ? order : 'asc'}
                    onClick={() => handleSort('createdAt')}
                  >
                    Created at
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 'large' }}>
                  Created by
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 'large' }}>
                  Status
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 'large' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            {contractData.length !== 0 ? (
              <>
                <TableBody>
                  {tempContractData
                    .slice()
                    .sort((a, b) => (order === 'asc' ? a.createdAt - b.createdAt : b.createdAt - a.createdAt))
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="left">{row?.contractName}</TableCell>
                        <TableCell sx={{ maxWidth: 200, overflow: 'hidden' }} align="left">
                          {row?.companyName}
                        </TableCell>
                        <TableCell align="left"> {formatFundingAmount(row?.purchasePriceSeller)}</TableCell>

                        <TableCell align="left"> {getTermsValue(row)}</TableCell>
                        <TableCell align="left">{moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                        <TableCell align="left">{row?.createdBy}</TableCell>
                        <TableCell align="left">
                          <Button
                            style={{
                              background: '#E9FBEC',
                              color: '#5AC461',
                              borderRadius: '5px',
                              fontSize: '12px',
                            }}
                          >
                            {row.status.replace('document.', '')}
                          </Button>
                        </TableCell>
                        <TableCell align="left" style={{}}>
                          <div style={{ display: 'flex' }}>
                            <a href={row.link} target="_blank" rel="noopener noreferrer">
                              <VisibilityIcon />
                            </a>
                            <EditIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                const termsValue = getTermsValue(row);
                                if (row?.type === 'Contract') {
                                  handleDialogOpenQtm(row?.id, termsValue);
                                } else {
                                  handleDialogOpenContract(row?.id, termsValue);
                                }
                              }}
                            />

                            <ContactMailIcon
                              sx={{ color: '#5AC461', cursor: 'pointer' }}
                              onClick={() => {
                                handleOpen2()
                                setEmailContractData(row)
                              }}
                            />
                            <DeleteIcon
                              sx={{ color: '#C84E29', cursor: 'pointer' }}
                              onClick={() => {
                                handleDeleteContract(row.id)
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </>
            ) : (
              <div style={{ width: '250%' }}>
                <h2
                  style={{
                    textAlign: 'center',
                    marginTop: '10%',
                    marginBottom: '10%',
                    marginLeft: '30%',
                  }}
                >
                  No contracts available
                </h2>
              </div>
            )}
          </Table>
        </TableContainer>
        <Grid
          xs="12"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginY: '10px',
            paddingY: '10px',
          }}
        >
          <PaginationComponent totalSize={totalSize} currentPage={currentPage} onChangePage={handlePageChange} />
        </Grid>
      </div>
    </>
  )
}
