import React from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Container, TextField, Button, CircularProgress } from "@mui/material";
import axios from "../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import forgotPassword from "../assets/forgotPassword.png";

const validationSchema = yup.object({
  username: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const ForgotPassword = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          marginTop: "3%",
          paddingBottom: "2%",
          borderBottom: "2px solid #E7F5FF",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Forgot Password</h1>
      </div>

      <ToastContainer />
      <Container
        maxWidth="sm"
        // sx={{
        //   display: "flex",
        //   height: "56vh",
        //   alignItems: "center",
        //   justifyContent: "center",
        // }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={forgotPassword} alt="" style={{marginTop:"4%"}}/>

          <div style={{ marginTop: "20%" }}>
            <span style={{ fontWeight: "bold", fontSize: "larger" }}>
              Please enter registered email ID
            </span>
            <div style={{ marginTop: "5%" }}>
              <span style={{ color: "#BEBBBB" }}>
                No worries, we’ll send you reset instructions on your email
              </span>
            </div>
          </div>

          <Formik
            enableReinitialize
            // validationSchema={validationSchema}
            initialValues={{ username: ""}}
            onSubmit={(values, { setSubmitting }) => {

              let data ={
                email: values.username
              }
              axios
                .post(`admin/forgetPassword`, data)
                .then((data) => {

                  
                  setSubmitting(false);
                  toast.success("Reset link sent to your email address")
                  
                })
                .catch((error) => {
                  setSubmitting(false);
                  toast.error(error.response.data.data.message);
                });
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div style={{ marginTop: "10%" }}>
                  <span
                    style={{
                      float: "left",
                      fontWeight: "bold",
                      fontSize: "120%",
                    }}
                  >
                    Email*
                  </span>
                  <Field
                    name="username"
                    as={TextField}
                    // label="Email*"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.username && touched.username)}
                    helperText={<ErrorMessage name="username" />}
                  />
                </div>

                <div style={{}}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{ marginTop: "40%", background: "#6040B0", borderRadius:"7px" }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <span style={{ fontWeight: "bold", fontSize: "115%" }}>
                        Send Code{" "}
                      </span>
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </>
  );
};
