import { Container, Grid } from '@mui/material'
import { TextCurrencyField } from './TextCurrencyField'
import { TextFieldInput } from './TextField'
import { ThirdPartyPayOff } from './ThirdPartyPayOff'

export const GAFLUTDisclosure = ({ values, errors, touched, formik }) => {

  return (
    <>
      <h1 style={{ marginLeft: '3%' }}>GA FL UT Disclosure</h1>
      <Container maxWidth="xl">
        <Grid item xs={12} container spacing={3}>
          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            allowFloat={true}
            disabled={true}
            value={values?.purchasePricePaidToSeller}
            name="GAPurchasePrice"
            heading="Purchase Price"
          />
          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            allowFloat={true}
            disabled={true}
            value={
              values?.wireFee
            }
            name="GAWireFee"
            heading="Wire Fee"
          />
          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            allowFloat={true}
            disabled={true}
            value={values?.originationFee}
            name="GAWireFee"
            heading="Origination Fee"
          />
          <TextCurrencyField
            name="GaIB"
            error={errors.GaIB}
            touched={touched.GaIB}
            heading="Internal Balance"
            allowFloat={true}
            onChange={(e) => {
              e.preventDefault()
              const numericValue = e.target.value.replace(/[^0-9.]/g, '')
              if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                // Set field value directly without relying on Formik's onChange
                formik.current.setFieldValue('GaIB', numericValue)

              } else {
                formik.current.setFieldError('GaIB', 'Please enter a valid number')
              }
            }}
          />
          <TextFieldInput
            name="Gafruquency"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Frequency"
            disabled={true}
          />
          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            allowFloat={true}
            disabled={true}
            value={values.purchasedAmmountofFutureReceipts}
            name="GAOriginationFee"
            heading="Purchase Amount"
          />

          <TextFieldInput
            name="GaTM"
            error={errors.GaTM}
            touched={touched.GaTM}
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
            }}
            type={'number'}
            heading="Terms in Month"
            onChange={(e) => {
              const { value } = e.target
              formik.current.setFieldValue('GaTM', value)
              let first = (+formik?.current?.values?.purchasedAmmountofFutureReceipts) -
                (+formik?.current?.values?.purchasePricePaidToSeller)
              let sec = (+formik?.current?.values?.administrativeFee * value || 0)
              let thrd = first + sec
              formik.current.setFieldValue(
                'GaTDC',
                thrd
              )
            }}
          />

          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            allowFloat={true}
            disabled={true}
            value={parseFloat(
              (values?.purchasePricePaidToSeller || 0) -
              (values?.priorBalance || 0) -
              (values?.processingFee || 0) -
              (values?.originationFee || 0)).toFixed(2)
            }
            name="GANetAmount"
            heading="Net Amount"
          />

          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            disabled={true}
            allowFloat={true}
            value={
              ((+formik?.current?.values?.purchasedAmmountofFutureReceipts || 0)
                + ((+formik?.current?.values?.administrativeFee || 0) * (+formik?.current?.values?.GaTM || 0))).toFixed(2)
            }
            name="GaTAP"
            heading="Total amount to be paid to us"
          />

          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            allowFloat={true}
            disabled={true}
            value={
              ((+values?.purchasedAmmountofFutureReceipts) -
                (+values?.purchasePricePaidToSeller) + (+values?.administrativeFee * values?.GaTM)).toFixed(2)
            }
            name="GaTDC"
            heading="Total Dollar Cost"
          />

          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            allowFloat={true}
            disabled={true}
            value={
              parseFloat(+values?.originationFee +
                +formik?.current?.values?.GaIB +
                +formik?.current?.values?.processingFee +
                +formik?.current?.values?.wireFee +
                (+formik?.current?.values?.GaAmount +
                  +formik?.current?.values?.GaAmount2 +
                  +formik?.current?.values?.GaAmount3)).toFixed(2)
            }
            name="GaADFP"
            heading="Amount deducted from funding provided"
          />
        </Grid>
        <ThirdPartyPayOff disclosure="Ga" errors={errors} touched={touched} formik={formik} />
      </Container>
    </>
  )
}
