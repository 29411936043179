import { Container, Grid } from '@mui/material'
import { TextCurrencyField } from './TextCurrencyField'
import { TextFieldInput } from './TextField'

export const ThirdPartyPayOff = ({ disclosure, values, errors, touched, formik }) => {
  return (
    <>
      <h1>THIRD PARTY PAYOFF</h1>
      <Container maxWidth="xl">
        <Grid item xs={12} container spacing={3}>
          {['', '2', '3']?.map((item, index) => {
            return (
              <>
                <TextFieldInput
                  md={6}
                  name={`${disclosure}name${item}`}
                  error={errors?.[`${disclosure}name${item}`]}
                  touched={touched?.[`${disclosure}name${item}`]}
                  spanStyle={{
                    float: 'left',
                    fontWeight: 'bold',
                  }}
                  heading="Name"
                  onChange={(e) => {
                    const { value } = e.target
                    formik.current.setFieldValue(`${disclosure}name${item}`, value)
                  }}
                />
                <TextCurrencyField
                  md={6}
                  name={`${disclosure}Amount${item}`}
                  error={errors?.[`${disclosure}Amount${item}`]}
                  touched={touched?.[`${disclosure}Amount${item}`]}
                  heading="Amount"
                  value={values?.[`${disclosure}Amount${item}`] || 0}
                  allowFloat={true}
                  onChange={(e) => {
                    e.preventDefault()
                    const numericValue = e.target.value.replace(/[^0-9.]/g, '')
                    if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                      formik.current.setFieldValue(`${disclosure}Amount${item}`, numericValue)
                    } else {
                      formik.current.setFieldError(`${disclosure}Amount${item}`, 'Please enter a valid number')
                    }
                  }}
                />
              </>
            )
          })}
        </Grid>
      </Container>
    </>
  )
}
