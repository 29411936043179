import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import { ToastContainer, toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: "7px",
};

const validationSchema = yup.object({
  username: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function Users() {
  const navigate = useNavigate();

  const [refresh, setRefresh] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [singleUserData, setSingleUserData] = React.useState({});

  const [rowId, setRowId] = React.useState(0);

  const [radioStatus, setRadioStatus] = React.useState("");

  //Edit modal states
  const [editOpen, setEditOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditOpen(true);
  const handleEditModalClose = () => setEditOpen(false);

  //Add modal states

  const [addOpen, setAddOpen] = React.useState(false);
  const handleAddModalOpen = () => setAddOpen(true);
  const handleAddModalClose = () => setAddOpen(false);

  React.useEffect(() => {
    axios
      .get(`admin/getAllUsers`)
      .then((data) => {
        console.log("response data", data);
        setUsersData(data.data.data.users);
      })
      .catch((error) => {
        toast.error(error.response.data.data.message);
      });
  }, [refresh]);

  const getSingleUserData = async (id) => {
    axios
      .get(`admin/singleUser/` + id)
      .then((data) => {
        setSingleUserData(data.data.data.user);
        setRadioStatus(data.data.data.user.userStatus);
      })
      .catch((error) => {
        toast.error(error.response.data.data.message);
      });
  };

  const deleteUser = async (id) => {
    axios
      .delete(`admin/deleteUser/` + id)
      .then((data) => {
        // toast.success("Success");
        setRefresh(!refresh);
      })
      .catch((error) => {
        toast.error(error.response.data.data.message);
      });
  };

  console.log("radioStatus", radioStatus);

  return (
    <>
      <ToastContainer />

      {/* Add User Modal */}

      <div>
        <Modal
          open={addOpen}
          onClose={handleAddModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ textAlign: "center", fontWeight: "140" }}
            >
              Add User Form
            </Typography>
            <Formik
              enableReinitialize
              // validationSchema={validationSchema}
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                contactNo: "",
                password: "",
                status: "",
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                let data = {
                  email: values.email,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  password: values.password,
                  contactNo: values.contactNo,
                  userStatus: values.status,
                };

                axios
                  .post(`admin/addUser`, data)
                  .then((data) => {
                    console.log("response data", data);
                    setSubmitting(false);
                    toast.success("Success");
                    setAddOpen(false);
                    resetForm();
                    setRefresh(!refresh);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    toast.error(error.response.data.data.message);
                  });
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      First Name
                    </span>
                    <Field
                      name="firstName"
                      as={TextField}
                      // label="Email*"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.firstName && touched.firstName)}
                      helperText={<ErrorMessage name="firstName" />}
                    />
                  </div>

                  <div style={{ marginTop: "3%" }}>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Last Name{" "}
                    </span>
                    <Field
                      name="lastName"
                      as={TextField}
                      // label="Password*"
                      variant="outlined"
                      // type="password"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.lastName && touched.lastName)}
                      helperText={<ErrorMessage name="lastName" />}
                      // sx={{border:"2px solid black"}}
                    />
                  </div>

                  <div style={{ marginTop: "3%" }}>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Email{" "}
                    </span>
                    <Field
                      name="email"
                      as={TextField}
                      // label="Password*"
                      variant="outlined"
                      type="email"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.email && touched.email)}
                      helperText={<ErrorMessage name="email" />}
                      // sx={{border:"2px solid black"}}
                    />
                  </div>
                  <div style={{ marginTop: "3%" }}>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Contact Number{" "}
                    </span>
                    <Field
                      name="contactNo"
                      as={TextField}
                      // label="Password*"
                      variant="outlined"
                      // type="password"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.contactNo && touched.contactNo)}
                      helperText={<ErrorMessage name="contactNo" />}
                      // sx={{border:"2px solid black"}}
                    />
                  </div>
                  <div style={{ marginTop: "3%" }}>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Password{" "}
                    </span>
                    <Field
                      name="password"
                      as={TextField}
                      // label="Password*"
                      variant="outlined"
                      type="password"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.password && touched.password)}
                      helperText={<ErrorMessage name="password" />}
                      // sx={{border:"2px solid black"}}
                    />
                  </div>

                  <div id="my-radio-group">
                    {" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Status{" "}
                    </span>
                  </div>

                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    style={{ marginTop: "4%" }}
                  >
                    <label>
                      <Field type="radio" name="status" value="Active" />
                      Active
                    </label>
                    <label>
                      <Field type="radio" name="status" value="Inactive" />
                      Inactive
                    </label>
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                      sx={{
                        marginTop: "1rem",
                        background: "#6040B0",
                        borderRadius: "7px",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "115%" }}>
                        Create
                      </span>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </div>

      {/* Add User Modal End  */}

      {/* Edit Modal */}

      <div>
        <Modal
          open={editOpen}
          onClose={handleEditModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ textAlign: "center", fontWeight: "140" }}
            >
              Edit User Form
            </Typography>
            <Formik
              enableReinitialize
              // validationSchema={validationSchema}
              initialValues={{
                firstName: singleUserData.firstName,
                lastName: singleUserData.lastName,
                email: singleUserData.email,
                contactNo: singleUserData.contactNo,
                password: '',
                status: singleUserData.status,
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                let data = {
                  email: values.email,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  password: values.password,
                  contactNo: values.contactNo,
                  userStatus: radioStatus,
                };

                axios
                  .put(`admin/updateUser/` + rowId, data)
                  .then((data) => {
                    console.log("response data", data);
                    setSubmitting(false);
                    toast.success("Success");
                    setEditOpen(false);
                    resetForm();
                    setRefresh(!refresh);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    toast.error(error.response.data.data.message);
                  });
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      First Name
                    </span>
                    <Field
                      name="firstName"
                      as={TextField}
                      // label="Email*"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.firstName && touched.firstName)}
                      helperText={<ErrorMessage name="firstName" />}
                    />
                  </div>

                  <div style={{ marginTop: "3%" }}>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Last Name{" "}
                    </span>
                    <Field
                      name="lastName"
                      as={TextField}
                      // label="Password*"
                      variant="outlined"
                      // type="password"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.lastName && touched.lastName)}
                      helperText={<ErrorMessage name="lastName" />}
                      // sx={{border:"2px solid black"}}
                    />
                  </div>

                  <div style={{ marginTop: "3%" }}>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Email{" "}
                    </span>
                    <Field
                      name="email"
                      as={TextField}
                      // label="Password*"
                      variant="outlined"
                      type="email"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.email && touched.email)}
                      helperText={<ErrorMessage name="email" />}
                      // sx={{border:"2px solid black"}}
                    />
                  </div>
                  <div style={{ marginTop: "3%" }}>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Contact Number{" "}
                    </span>
                    <Field
                      name="contactNo"
                      as={TextField}
                      // label="Password*"
                      variant="outlined"
                      // type="password"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.contactNo && touched.contactNo)}
                      helperText={<ErrorMessage name="contactNo" />}
                      // sx={{border:"2px solid black"}}
                    />
                  </div>
                  <div style={{ marginTop: "3%" }}>
                    <span
                      style={{
                        float: "left",
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Password{" "}
                    </span>
                    <Field
                      name="password"
                      as={TextField}
                      // label="Password*"
                      variant="outlined"
                      type="password"
                      fullWidth
                      margin="normal"
                      error={Boolean(errors.password && touched.password)}
                      helperText={<ErrorMessage name="password" />}
                      // sx={{border:"2px solid black"}}
                    />
                  </div>

                  <div id="my-radio-group">
                    {" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      Status{" "}
                    </span>
                  </div>

                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    style={{ marginTop: "4%" }}
                  >
                    <label>
                      <Field
                        type="radio"
                        name="status"
                        value="Active"
                        checked={radioStatus == "Active" ? true : false}
                        onChange={(e) => {
                          setRadioStatus(e.target.value);
                        }}
                      />
                      Active
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="status"
                        value="Inactive"
                        checked={radioStatus == "Inactive" ? true : false}
                        onChange={(e) => {
                          setRadioStatus(e.target.value);
                        }}
                      />
                      Inactive
                    </label>
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                      sx={{
                        marginTop: "1rem",
                        background: "#6040B0",
                        borderRadius: "7px",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "115%" }}>
                        Save
                      </span>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </div>

      {/* Edit Modal End  */}

      <div
        style={{
          background: "white",
          height: "80vh",
           
          width: {sm:"100%", md:"95%"},
          padding: "2%",
          borderRadius: "10px",
          border: "1px solid #BEBBBB",
          overflow:"auto",
          width: "95%", display: "table", tableLayout: "fixed" ,

        }}
      >
        <h2>Users updates</h2>
        <Button
          sx={{ float: "right", mb: 3 }}
          variant="contained"
          onClick={() => [handleAddModalOpen()]}
        >
          Create User
        </Button>
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: "large" }}>
                  First Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Last Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Email
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Contact number
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Password
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "large",  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            {usersData.length !== 0 ? (
              <>
                <TableBody>
                  {usersData.map((row) => (
                    <TableRow
                      key={row.id}
                      //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row.firstName}</TableCell>

                      <TableCell align="left">{row.lastName}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.contactNo}</TableCell>
                      <TableCell align="left">{row.password}</TableCell>
                      <TableCell align="left">
                        {row.userStatus == "Active" ? (
                          <>
                            <Button
                              style={{
                                background: "#E9FBEC",
                                color: "#5AC461",
                                borderRadius: "5px",
                                width: "70%",
                              }}
                            >
                              Active
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              sx={{
                                background: "#F8E9E7",
                                color: "#C84E29",
                                borderRadius: "5px",
                                width: "70%",
                              }}
                            >
                              Inactive
                            </Button>
                          </>
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          // display: "flex",
                          // justifyContent: "",
                          border: "none",
                          marginTop: "2%",
                          background:""
                        }}
                      >
                        {/* <VisibilityIcon sx={{ color: "#6040B0" }} />

                        <ManageSearchIcon sx={{ color: "#6040B0" }} /> */}

                        <EditIcon
                          sx={{ cursor: "pointer", paddingRight:"2.5rem"}}
                          onClick={() => {
                            getSingleUserData(row.id);
                            handleEditModalOpen(true);
                            setRowId(row.id);
                          }}
                        />

                        <DeleteIcon
                          sx={{ color: "#C84E29", cursor: "pointer" }}
                          onClick={() => {
                            deleteUser(row.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <>
                <div style={{ width: "400%" }}>
                  <h2
                    style={{
                      textAlign: "center",
                      marginTop: "10%",
                      marginBottom: "10%",
                    }}
                  >
                    No users available
                  </h2>
                </div>
              </>
            )}
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
