import { Container, Grid } from '@mui/material'
import { TextFieldInput } from './TextField'
import { TextCurrencyField } from './TextCurrencyField'

export const PrePaymentAddendum = ({ errors, touched, setSlotsNumber, formik, slotsNumber }) => {
  const slotsData = ['Day 1-30', 'Day 31-60', 'Day 61-90', 'Day 91-120', 'Day 121-150', 'Day 151-180']
  return (
    <>
      <h1 style={{ marginLeft: '3%' }}>Prepayment Addendum</h1>
      <Container maxWidth="xl">
        <Grid item xs={12} container spacing={3}>
          <TextFieldInput
            name="guarantorpre"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Guarantor"
            disabled={true}
          />

          <TextFieldInput
            name="merchantpre"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Merchant"
            disabled={true}
          />

          <TextFieldInput
            name="date"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Date"
            disabled={true}
          />

          <TextFieldInput
            name="selectedSlots"
            error={errors.selectedSlots}
            touched={touched.selectedSlots}
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            type="number"
            heading="Early Delivery Period Amount Slots"
            onChange={(e) => {
              if (e.target.value > -1 && e.target.value <= 6) {
                setSlotsNumber(e.target.value)
                formik?.current?.setFieldValue('selectedSlots', e.target.value)
              }
            }}
          />

          {slotsNumber > 0 && (
            <Grid item container spacing={2}>
              {Array.from({ length: slotsNumber }, (_, index) => (
                <TextCurrencyField
                  md={2}
                  heading={slotsData[index]}
                  spanStyle={{
                    fontWeight: 'bold',
                    fontSize: '120%',
                  }}
                  allowFloat={true}
                  name={`slot${index}`}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9.]/g, '')

                    if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                      formik?.current.setFieldValue(`slot${index}`, numericValue)
                    } else {
                      formik?.current.setFieldError(`slot${index}`, 'Please enter a valid number')
                    }
                  }}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  )
}
