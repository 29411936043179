import React, { useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import axios from "../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qbTransparent from "../assets/qbTransparent.png";

const validationSchema = yup.object({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const LoginPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const Item = styled(Paper)(({ theme }) => ({}));
  return (
    <>
      <div
        style={{
          marginTop: "3%",
          paddingBottom: "4%",
          borderBottom: "2px solid #E7F5FF",
        }}
      >
        {/* <h1 style={{ textAlign: "center" }}>Sign In</h1> */}
      </div>
      <ToastContainer />
      <Grid
        maxWidth
        // maxWidth="sm"
        sx={{
          display: {md:"flex",sm:"block",xs:"block"},
          height: "56vh",
          alignItems: "center",
          justifyContent: "center",
         
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={qbTransparent} alt="" />

          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, { setSubmitting }) => {
              axios
                .post(`admin/login/`, values)
                .then((data) => {
                  setSubmitting(false);
                  localStorage.setItem("token", data?.data?.data?.token);
                  localStorage.setItem("user", JSON.stringify(data?.data?.data?.user));
                  let role = "admin";
                  localStorage.setItem("role", role);
                  // console.log("user role--->", localStorage.getItem("role", role))

                  navigate("/");
                })
                .catch((error) => {
                  setSubmitting(false);
                  toast.error(error?.response?.data?.data?.message);
                });
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Container>
                  <Grid
                    item
                    xs={12}
                    // style={{display:"flex",alignItems:"center",flexDirection:"column"}}
                  gap={2}
                 style={{ display:"flex", justifyContent:"center"}}
                    container
                  >
                       
                    <Grid item xs={12} md={6}  >
                    <div
                          style={{
                            float: "left",
                            fontWeight: "bold",
                            fontSize: "120%",
                          }}
                        >
                          Email*
                        </div>
                        <Field
                          name="email"
                          as={TextField}
                          variant="outlined"
                          fullWidth
                          style={{ width: "100%" }} 
                          margin="normal"
                          error={Boolean(errors.email && touched.email)}
                          helperText={<ErrorMessage name="email" />}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                 
                    </Grid>
                       
               
                    <Grid item xs={12} md={6}  >
                    <div
                          style={{
                            float: "left",
                            fontWeight: "bold",
                            fontSize: "120%",
                          }}
                        >
                          Password*
                        </div>
                     
                      <Field
                        name="password"
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={{ width: "100%" }}
                        margin="normal"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(errors.password && touched.password)}
                        helperText={<ErrorMessage name="password" />}
                      />
                   
                  </Grid>
                  <Grid item xs={12} md={6} >
                  <div>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                      sx={{
                        marginTop: "1rem",
                        background: "#6040B0",
                        borderRadius: "7px",
                      }}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <span style={{ fontWeight: "bold", fontSize: "115%" }}>
                          Log In
                        </span>
                      )}
                    </Button>
                  </div>
                  </Grid>


                  <Grid item xs={12} md={6}  >
                  <div style={{  display:"flex" , justifyContent:"space-between"}}>
                  <div
                      style={{
                        marginTop: "3%",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/forgot-password");
                      }}
                    >
                      Forgot Password?
                    </div>
                    <div
                      style={{
                        // float: "right",
                        marginTop: "3%",
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "#1877F2",
                      }}
                      onClick={() => {
                        navigate("/manager");
                      }}
                    >
                      Login Manager ?
                    </div>
                  </div>
                  </Grid>
                  </Grid>

                 
                

                
                </Container>
              </Form>
            )}
          </Formik>
        </div>
      </Grid>
    </>
  );
};
