import { Container, Grid } from '@mui/material'
import { TextCurrencyField } from './TextCurrencyField'
import { TextPercentageField } from './TextPercentageField'
import { TextFieldInput } from './TextField'
import { ThirdPartyPayOff } from './ThirdPartyPayOff'
import { periodicFrequencyValue } from '../utils/helperFunctions'


export const NYDisclosure = ({ values, errors, touched, formik }) => {
  return (
    <>
      <h1 style={{ marginLeft: '3%' }}>NY Disclosure</h1>
      <Container maxWidth="xl">
        <Grid item xs={12} container spacing={3}>
          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            disabled={true}
            value={values.purchasePricePaidToSeller}
            name="NyFA"
            heading="Funding Amount"
          />

          <TextPercentageField
            name={'NyAPR'}
            error={errors.NyAPR}
            touched={touched.NyAPR}
            heading={'APR'}
            onChange={(e) => {
              formik.current.setFieldValue('NyAPR', e.target.value)
            }}
          />
          <TextFieldInput
            name="NyTM"
            error={errors.NyTM}
            touched={touched.NyTM}
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
            }}
            type={'number'}
            heading="Terms in Month"
            onChange={(e) => {
              const { value } = e.target
              formik.current.setFieldValue('NyTM', value)
              formik.current.setFieldValue('NyET', value ? value * 30 : 0)
              let first =
                (+formik?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
                (+formik?.current?.values?.purchasePricePaidToSeller || 0) + (+formik?.current?.values?.wireFee || 0) + (+formik?.current?.values?.processingFee || 0)
              let second = (+formik?.current?.values?.administrativeFee || 0) * (+value || 0)
              let third = +first + +second

              formik.current.setFieldValue('NyFC', +third.toFixed(2))
              formik.current.setFieldValue('NyPrepayment', +third.toFixed(2))
            }}
          />
          <TextFieldInput
            name="NyFC"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Finance charge(s)"
            disabled={true}
          />

          <TextFieldInput
            name="NyTPA"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            type="number"
            heading="Estimated Total Payment Amount"
            disabled={true}
          />

          <TextFieldInput
            name="NyEMC"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Estimated Monthly Cost"
            disabled={true}
          />

          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            disabled={true}
            value={values.initialPeriodicAmount}
            name="NyEP"
            heading="Estimated Payment(s)"
          />

          <TextPercentageField name={'NyRemit'} disabled={true} heading={'Remit'} />

          <TextFieldInput
            name="Nyfruquency"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Frequency"
            disabled={true}
          />

          <TextFieldInput
            name="NyET"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Estimated Term"
            disabled={true}
          />

          <TextFieldInput
            name="NyPrepayment"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Prepayment"
            disabled={true}
          />

          <TextCurrencyField
            name="NyIB"
            error={errors.NyIB}
            touched={touched.NyIB}
            heading="Internal Balance"
            allowFloat={true}
            onChange={(e) => {
              e.preventDefault()
              const numericValue = e.target.value.replace(/[^0-9.]/g, '')
              if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                // Set field value directly without relying on Formik's onChange
                formik.current.setFieldValue('NyIB', numericValue)
              } else {
                formik.current.setFieldError('NyIB', 'Please enter a valid number')
              }
            }}
          />

          <TextCurrencyField
            name="NyMonthlyAvgRev"
            error={errors.NyMonthlyAvgRev}
            touched={touched.NyMonthlyAvgRev}
            heading=" Monthly Average Revenue"
            allowFloat={true}
            onChange={(e) => {
              e.preventDefault()
              const numericValue = e.target.value.replace(/[^0-9.]/g, '')
              if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                // Set field value directly without relying on Formik's onChange
                formik.current.setFieldValue('NyMonthlyAvgRev', numericValue)
              } else {
                formik.current.setFieldError('NyMonthlyAvgRev', 'Please enter a valid number')
              }
            }}
          />
        </Grid>
        <ThirdPartyPayOff disclosure="Ny" errors={errors} touched={touched} formik={formik} />
      </Container>
    </>
  )
}
