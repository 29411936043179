import React from "react";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Container, TextField, Button, CircularProgress } from "@mui/material";
import axios from "../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import resetPassword from "../assets/resetPassword.png";

const validationSchema = yup.object({
  username: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup.string().required("Confirm Password is required"),
});

export const ResetPassword = () => {

  const {token} = useParams()

  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          marginTop: "3%",
          paddingBottom: "2%",
          borderBottom: "2px solid #E7F5FF",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Reset Password</h1>
      </div>

      <ToastContainer />
      <Container
        maxWidth="sm"
        // sx={{
        //   display: "flex",
        //   height: "56vh",
        //   alignItems: "center",
        //   justifyContent: "center",
        // }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={resetPassword} alt="" style={{ marginTop: "4%" }} />

          <Formik
            enableReinitialize
            // validationSchema={validationSchema}
            initialValues={{ username: "", password: "" }}
            onSubmit={(values, { setSubmitting }) => {


              console.log("im here");


              if(values.password !== values.confirmPassword){
                toast.error("New and Confirm passwords are not same")
                setSubmitting(false)
              }
              else{
                let data ={
                  newPassword:values.confirmPassword,
                  token
                }
                axios
                  .post(`admin/resetPassword`, data)
                  .then((data) => {
                    setSubmitting(false);
                    toast.success("Password resetted")

                    setTimeout(()=>{
                      navigate("/")

                    },2500)

                    
  
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    toast.error(error.response.data.data.message);
                  });
              }}
              }

            
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div style={{ marginTop: "3%" }}>
                  <span
                    style={{
                      float: "left",
                      fontWeight: "bold",
                      fontSize: "120%",
                    }}
                  >
                    New Password*
                  </span>
                  <Field
                    name="password"
                    as={TextField}
                    // label="Password*"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.password && touched.password)}
                    helperText={<ErrorMessage name="password" />}
                    // sx={{border:"2px solid black"}}
                  />
                </div>
                <div style={{ marginTop: "3%" }}>
                  <span
                    style={{
                      float: "left",
                      fontWeight: "bold",
                      fontSize: "120%",
                    }}
                  >
                    Confirm Password*
                  </span>
                  <Field
                    name="confirmPassword"
                    as={TextField}
                    // label="Password*"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      errors.confirmPassword && touched.confirmPassword
                    )}
                    helperText={<ErrorMessage name="confirmPassword" />}
                    // sx={{border:"2px solid black"}}
                  />
                </div>

                <div style={{}}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{
                      marginTop: "40%",
                      background: "#6040B0",
                      borderRadius: "7px",
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <span style={{ fontWeight: "bold", fontSize: "115%" }}>
                        Reset{" "}
                      </span>
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </>
  );
};
